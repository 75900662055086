<script>
import Vue from "vue";
import Layout from "@/router/layouts/main";
import Multiselect from "vue-multiselect";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Repository from "@/app/repository/repository-factory";
import gridSetup from "@/components/data/gridSetup";
import { roleService } from "@/app/service/roleService";

const PaymentContactRepository = Repository.get("PaymentContactRepository");

/**
 * Lista kontaktów
 */
export default {
  page: {
    title: "Lista kontaktów",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, Multiselect },
  data() {
    return {
      ...gridSetup.data(),
      header: {
        title: "Lista kontaktów",
        items: [
          {
            text: "Strona główna",
            to: { name: "home" },
          },
          {
            text: "Lista kontaktów",
          },
        ],
      },
      fieldsSelectOptions: [],
    };
  },
  created() {
    this.getStateGridFilters();
  },
  methods: {
    fieldsComputed(fieldList) {
      let fieldsComputed = [];
      let fieldsSelected = [];
      const selectedCount = this.filters.grid.fieldsSelected.length;
      const defaultField = ["name", "email", "isActive"];

      // If selected is empty (eg. no save state)
      if (selectedCount === 0) {
        Object.entries(fieldList).forEach(([key, val]) => {
          if (defaultField.includes(val.key)) {
            fieldsSelected.push({
              text: val.label,
              value: val.key,
              label: val.label,
              key: val.key,
              sortable: val.sortable,
            });

            fieldsComputed.push({
              text: val.label,
              value: val.key,
              label: val.label,
              key: val.key,
              sortable: val.sortable,
            });
          }
        });

        this.filters.grid.fieldsSelected = fieldsSelected;
      }

      // If selected is not empty
      if (selectedCount > 0) {
        Object.entries(this.filters.grid.fieldsSelected).forEach(
          ([key, val]) => {
            fieldsComputed.push({
              text: val.label,
              value: val.key,
              label: val.label,
              key: val.key,
              sortable: val.sortable,
            });
          }
        );
      }

      fieldsComputed.push({ key: "action", label: "Akcje", thClass: "last-col-sticky", tdClass: "last-col-sticky" });
      this.grid.fields = fieldsComputed;
    },
    gridProvider(ctx) {
      // if (localStorage.getItem("gridContactsPaginationState") !== null) {
      //   ctx.currentPage = JSON.parse(
      //     localStorage.getItem("gridContactsPaginationState")
      //   );
      // }

      // if (localStorage.getItem("gridContactsSearchState") !== null) {
      //   this.filters.grid.filter = JSON.parse(
      //     localStorage.getItem("gridContactsSearchState")
      //   );
      // }

      const parameters =
        "?page=" +
        ctx.currentPage +
        "&maxItems=" +
        ctx.perPage +
        "&search=" +
        this.filters.grid.filter +
        "&orderBy=" +
        this.filters.grid.sortBy +
        "&orderDesc=" +
        this.filters.grid.sortDesc;
      const promise = PaymentContactRepository.getGrid(parameters);

      return promise
        .then((data) => {
          const fields = data.data.info.fields;
          this.grid.totalRows = data.data.info.countAllItems;

          // if (localStorage.getItem("gridContactsPaginationState") !== null) {
          //   this.grid.currentPage = JSON.parse(
          //     localStorage.getItem("gridContactsPaginationState")
          //   );
          // }

          let fieldsToSelect = [];

          Object.entries(fields).forEach(([key, val]) => {
            if (val.disabled !== true) {
              fieldsToSelect.push({
                text: val.label,
                value: key,
                label: val.label,
                key: key,
                sortable: val.sortable,
              });
            }
          });
          this.fieldsSelectOptions = fieldsToSelect;
          this.fieldsComputed(fieldsToSelect);

          return data.data.data || [];
        })
        .catch((error) => {
          console.log(error);
          return [];
        });
    },
    refreshGrid(value, id) {
      this.$refs.table_contacts.refresh();
    },
    clearGridFilters() {
      localStorage.removeItem("gridContactsPaginationState");
      localStorage.removeItem("gridContactsSearchState");
      this.grid.currentPage = 1;
      this.filters.grid.filter = "";
      this.filters.grid.filterOn = [""];
      this.filters.grid.perPage = 25;
      this.filters.grid.sortBy = "createdAt";
      this.filters.grid.sortDesc = true;
      this.filters.grid.fieldsSelected = [];
      this.saveStateGridFilters();
    },
    saveStateGridFilters() {
      localStorage.setItem(
        "gridContactListState",
        JSON.stringify(this.filters)
      );
      this.$refs.table_contacts.refresh();

      Vue.swal({
        icon: "success",
        toast: false,
        position: "top",
        title: "SUKCES!",
        text: "Filtry zostały zapisane!",
        showConfirmButton: false,
        timer: 3500,
      });
    },
    getStateGridFilters() {
      if (localStorage.getItem("gridContactListState") !== null) {
        this.filters = JSON.parse(localStorage.getItem("gridContactListState"));
      }
    },
  },
  computed: {
    totalFiltersLength() {
      return 0;
    },
    isAdmin() {
      return roleService.isAdmin();
    },
  },
  watch: {
    // "grid.currentPage": function () {
    //   localStorage.setItem(
    //     "gridContactsPaginationState",
    //     JSON.stringify(this.grid.currentPage)
    //   );
    // },
    // "filters.grid.filter": function () {
    //   localStorage.setItem(
    //     "gridContactsSearchState",
    //     JSON.stringify(this.filters.grid.filter)
    //   );
    // },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="header.title" :items="header.items" />

    <div class="row" v-if="isAdmin">
      <div class="col-12">
        <b-collapse id="collapse">
          <b-card class="shadow-sm">
            <div class="font-weight-bold" slot="header">Ustawienia kolumn i filtrów</div>
            <div class="form-group">
              <label class="typo__label">Wybierz widoczne kolumny tabeli</label>
              <multiselect v-model="filters.grid.fieldsSelected" :multiple="true" :options="fieldsSelectOptions" :preserve-search="true" track-by="value" label="text" placeholder="Wybierz kolumny" select-label="" deselect-label="Odznacz ✕" selected-label="Wybrane ✓" @input="fieldsComputed(fieldsSelectOptions)">
                <template slot="noOptions">Lista jest pusta.</template><template slot="noResult">Nie znaleziono elementów.</template>
              </multiselect>
            </div>
            <hr />
            <div class="row">
            </div>
            <div class="row">
              <div class="col text-left">
                <button type="button" class="btn btn-danger waves-effect waves-light" v-on:click="clearGridFilters">
                  <i class="mdi mdi mdi-trash-can-outline mr-1" /> Usuń ustawienia
                </button>
              </div>
              <div class="col text-right">
                <button type="button" class="btn btn-dark waves-effect waves-light" v-on:click="saveStateGridFilters">
                  <i class="dripicons-pin mr-1" /> Zapisz ustawienia
                </button>
              </div>
            </div>
          </b-card>
        </b-collapse>
        <div class="card shadow-sm">
          <div class="card-header">
            <div class="d-flex justify-content-between">
              <div class="font-weight-bold">Kontakty</div>
              <div>
                <i class="mdi mdi-format-columns mr-1"></i>Kolumny: <span class="badge badge-pill badge-soft-info font-size-12">{{ filters.grid.fieldsSelected.length + '/' + fieldsSelectOptions.length}}</span>
                <span class="ml-2" v-if="totalFiltersLength > 0"><i class="mdi mdi-filter-outline mr-1"></i>Aktywne filtry: <span class="badge badge-pill badge-soft-success font-size-12">{{ totalFiltersLength }}</span></span>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <b-form inline>
                  <b-form-select v-model="filters.grid.perPage" :options="grid.pageOptions" class="w-auto" />
                  <div class="ml-2 search-box">
                    <div class="position-relative">
                      <b-form-input v-model="filters.grid.filter" :debounce="350" placeholder="Szukaj..." :class="filters.grid.filter !='' ? 'border-success' : ''" />
                      <i class="ti-search search-icon" />
                    </div>
                  </div>
                </b-form>
              </div>
              <div class="col-md-6">
                <div class="text-right mt-2 mt-md-0 mb-2">
                  <b-link v-if="isAdmin" :to="{ name: 'Dodaj kontakt' }" class="btn btn-dark waves-effect waves-light mb-2 mr-2">
                    <i class="mdi mdi-plus mr-1" />Dodaj kontakt
                  </b-link>
                  <b-button v-b-toggle="'collapse'" class="waves-effect mb-2 collapsed" variant="success">
                    <i class="mdi mdi-filter-outline mr-1"></i>Filtry
                  </b-button>
                </div>
              </div>
            </div>
            <!-- End search -->

            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table id="table_contacts" ref="table_contacts" :items="gridProvider" :fields="grid.fields" responsive="sm" sticky-header="45vh" sort-icon-left :per-page="filters.grid.perPage" :current-page="grid.currentPage" :sort-by.sync="filters.grid.sortBy" :sort-desc.sync="filters.grid.sortDesc" :filter="filters.grid.filter" :filter-included-fields="filters.grid.filterOn">
                <template v-slot:table-busy>
                  <div class="text-center text-black">
                    <b-spinner class="align-middle" />
                    <strong> Ładowanie danych...</strong>
                  </div>
                </template>

                <template v-slot:cell(isActive)="data">
                  <span class="badge badge-pill badge-soft-success font-size-12" :class=" { 'badge-soft-success': data.value == true, 'badge-soft-danger': data.value == false }">
                    {{ data.value ? 'Tak' : 'Nie' }}</span>
                </template>

                <template v-slot:cell(createdAt)="data">
                  <span class="text-nowrap">
                    {{ data.value }}
                  </span>
                </template>

                <template v-slot:cell(action)="row">
                  <ul class="list-inline font-size-20 contact-links mb-0">
                    <li class="list-inline-item px-2" v-if="isAdmin">
                      <a v-b-tooltip.hover.topleft title="Edycja danych">
                        <b-link :to="{ name: 'Edycja kontaktu', params: { id: row.item.contactId } }" class="grid-action-btn">
                          <i class="font-size-14 far fa-edit" />
                        </b-link>
                      </a>
                    </li>
                  </ul>
                </template>
              </b-table>
            </div>

            <!-- Pagination -->
            <div class="row">
              <div class="col">
                <template v-if="grid.totalRows === 0">
                  <div class="d-flex justify-content-center mt-3">
                    <b>Nie znaleziono elementów</b>
                  </div>
                </template>
                <template v-if="grid.totalRows > 0">
                  <div class="dataTables_paginate paging_simple_numbers float-left ml-2">
                    Znaleziono {{ grid.totalRows }} rekordów.
                  </div>
                </template>
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-if="grid.totalRows > 0" v-model="grid.currentPage" :total-rows="grid.totalRows" :per-page="filters.grid.perPage" first-number last-number />
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
